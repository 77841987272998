<template>
  <div>
    <b-row>
      <b-col>
        <b-card no-body>
          <b-card-header>
            <b-col
              xs="12"
              md="5"
            >
              <b-form-datepicker
                v-model="filterParams.sdate"
                v-bind="{labelNoDateSelected: 'Başlangıç Tarihi',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                locale="tr"
                start-weekday="1"
              />
            </b-col>
            <b-col
              xs="12"
              md="5"
            >
              <b-form-datepicker
                v-model="filterParams.edate"
                v-bind="{labelNoDateSelected: 'Bitiş Tarihi',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                locale="tr"
                start-weekday="1"
              />
            </b-col>
            <b-col
              xs="12"
              md="2"
            >
              <b-button
                variant="primary"
                block
                :disabled="filterParams.sdate == null || filterParams.edate == null"
                @click="getSales"
              >
                <FeatherIcon icon="FilterIcon" />
                Görüntüle
              </b-button>
            </b-col>
          </b-card-header>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="loadingData">
      <b-col cols="12">
        <Loading />
      </b-col>
    </b-row>
    <b-row v-if="sales.length > 0 && !loadingData">
      <b-col cols="12">
        <b-card>
          <b-tabs
            pills
            fill
          >
            <b-tab active>
              <template #title>
                <feather-icon icon="PieChartIcon" />
                <span>Satış</span>
              </template>
              <Sales />
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="BoxIcon" />
                <span>Stok</span>
              </template>
              <stock-list />
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="LifeBuoyIcon" />
                <span>Test Sürüşü</span>
              </template>
              <test-drives />
            </b-tab>
            <!--            <b-tab>-->
            <!--              <template #title>-->
            <!--                <feather-icon icon="PackageIcon" />-->
            <!--                <span>Aksesuar</span>-->
            <!--              </template>-->
            <!--              <Accessories />-->
            <!--            </b-tab>-->
            <b-tab>
              <template #title>
                <feather-icon icon="UmbrellaIcon" />
                <span>Sigorta</span>
              </template>
              <Insurances />
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="RepeatIcon" />
                <span>Takas</span>
              </template>
              <Swaps />
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="MessageCircleIcon" />
                <span>Görüşme</span>
              </template>
              <Interviews />
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BRow,
  BCol,
  BButton,
  BFormDatepicker,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import Loading from '@/layouts/components/Loading.vue'
import Sales from '@/views/Reports/Sales/SalesGPR/Sales.vue'
import StockList from '@/views/Reports/Sales/SalesGPR/StockList.vue'
// import Accessories from '@/views/Reports/Sales/SalesGPR/Accessories.vue'
import Insurances from '@/views/Reports/Sales/SalesGPR/Insurances.vue'
import Swaps from '@/views/Reports/Sales/SalesGPR/Swaps.vue'
import Interviews from '@/views/Reports/Sales/SalesGPR/Interviews.vue'
import TestDrives from '@/views/Reports/Sales/SalesGPR/TestDrives.vue'

export default {
  name: 'SalesGPR',
  components: {
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BButton,
    BFormDatepicker,
    BTabs,
    BTab,
    Loading,
    Sales,
    // Accessories,
    Insurances,
    Swaps,
    Interviews,
    TestDrives,
    StockList,
  },
  data() {
    return {
      sdate: null,
      edate: null,
    }
  },
  computed: {
    filterParams() {
      return this.$store.getters['salesGpr/filterParams']
    },
    loadingData() {
      return this.$store.getters['salesGpr/getDataLoading'].sales
    },
    sales() {
      const data = this.$store.getters['salesGpr/getSales']
      if (data) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.getAccessories()
        this.getTestDrives()
        this.getInsurances()
        this.getSwaps()
        this.getInterviews()
      }
      return data
    },
  },
  created() {
    this.getSales()
  },
  methods: {
    getSales() {
      if (this.filterParams.sdate && this.filterParams.edate) {
        this.$store.dispatch('salesGpr/sales', {
          sdate: this.filterParams.sdate,
          edate: this.filterParams.edate,
        })
      }
    },
    getAccessories() {
      if (this.filterParams.sdate && this.filterParams.edate) {
        this.$store.dispatch('salesGpr/accessories', {
          sdate: this.filterParams.sdate,
          edate: this.filterParams.edate,
        })
      }
    },
    getTestDrives() {
      if (this.filterParams.sdate && this.filterParams.edate) {
        this.$store.dispatch('salesGpr/testDrives', {
          sdate: this.filterParams.sdate,
          edate: this.filterParams.edate,
        })
      }
    },
    getInsurances() {
      if (this.filterParams.sdate && this.filterParams.edate) {
        this.$store.dispatch('salesGpr/insurances', {
          sdate: this.filterParams.sdate,
          edate: this.filterParams.edate,
        })
      }
    },
    getSwaps() {
      if (this.filterParams.sdate && this.filterParams.edate) {
        this.$store.dispatch('salesGpr/swaps', {
          sdate: this.filterParams.sdate,
          edate: this.filterParams.edate,
        })
      }
    },
    getInterviews() {
      if (this.filterParams.sdate && this.filterParams.edate) {
        this.$store.dispatch('salesGpr/interviews', {
          sdate: this.filterParams.sdate,
          edate: this.filterParams.edate,
        })
      }
    },
  },
}
</script>
