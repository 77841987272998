<template>
  <div>
    <template v-if="loadingData">
      <loading class="mt-2" />
    </template>
    <template
      v-for="(swap,key) in swaps"
      v-else
      class="mt-2"
    >
      <b-table-simple
        hover
        caption-top
        caption-bottom
        responsive
      >
        <b-thead>
          <b-tr>
            <b-th
              colspan="4"
            >
              <div class="text-primary font-medium-4 font-weight-bolder">
                {{ swap.brand }}
              </div>
            </b-th>
          </b-tr>
          <b-tr>
            <b-th>Danışman</b-th>
            <b-th class="text-center">
              Araç Satışı
            </b-th>
            <b-th class="text-center">
              Takas
            </b-th>
            <b-th class="text-center">
              Takas Oranı
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(user,uKey) in swap.users"
            :key="uKey"
          >
            <b-td>{{ user.username }}</b-td>
            <b-td class="text-center width-250">
              {{ user.sales }}
            </b-td>
            <b-td class="text-center width-250">
              {{ user.swaps }}
            </b-td>
            <b-td class="text-center width-250">
              % {{ user.swap_percent }}
            </b-td>
          </b-tr>
        </b-tbody>
        <b-tfoot>
          <b-tr>
            <b-th>Toplam</b-th>
            <b-th class="text-center">
              {{ swap.totals.sales }}
            </b-th>
            <b-th class="text-center">
              {{ swap.totals.swaps }}
            </b-th>
            <b-th class="text-center">
              % {{ swap.totals.swap_percent }}
            </b-th>
          </b-tr>
        </b-tfoot>
      </b-table-simple>
      <hr :key="key">
    </template>
  </div>
</template>
<script>
import {
  BTableSimple, BTbody, BTd, BTfoot, BTh, BThead, BTr,
} from 'bootstrap-vue'
import Loading from '@/layouts/components/Loading.vue'

export default {
  name: 'Swaps',
  components: {
    Loading,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
    BTfoot,
  },
  computed: {
    swaps() {
      return this.$store.getters['salesGpr/getSwaps']
    },
    loadingData() {
      return this.$store.getters['salesGpr/getDataLoading'].swaps
    },
  },
}
</script>
